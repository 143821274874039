import { defineComponentClasses } from '@visiontree/vue-ui'

interface Structure {
  container: never
}

interface Options {
  gap?: 'sm' | 'md' | 'lg' | 'xl' | 'none'
  display?: 'inline' | 'block'
  direction?: 'row' | 'row-reverse' | 'col' | 'col-reverse'
  justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly'
  alignItems?: 'normal' | 'start' | 'end' | 'center' | 'stretch' | 'baseline'
}

export const getStackClasses = defineComponentClasses<Options, Structure>({
  classes: {
    container: '',
  },
  defaultVariants: {
    gap: 'none',
    display: 'block',
    direction: 'col',
    justifyContent: 'start',
    alignItems: 'start',
  },
  variants: {
    display: {
      inline: { container: 'inline-flex' },
      block: { container: 'flex' },
    },
    direction: {
      row: { container: 'flex-row' },
      'row-reverse': { container: 'flex-row-reverse' },
      col: { container: 'flex-col' },
      'col-reverse': { container: 'flex-col-reverse' },
    },
    gap: {
      sm: { container: 'gap-2' },
      md: { container: 'gap-4' },
      lg: { container: 'gap-6' },
      xl: { container: 'gap-8' },
      none: { container: 'gap-0' },
    },
    justifyContent: {
      start: { container: 'justify-start' },
      end: { container: 'justify-end' },
      center: { container: 'justify-center' },
      between: { container: 'justify-between' },
      around: { container: 'justify-around' },
      evenly: { container: 'justify-evenly' },
    },
    alignItems: {
      start: { container: 'items-start' },
      end: { container: 'items-end' },
      center: { container: 'items-center' },
      stretch: { container: 'items-stretch' },
      baseline: { container: 'items-baseline' },
    },
  },
})
