<template>
  <component :is="tagName" :class="classes.container">
    <slot />
  </component>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { getStackClasses } from './utils/getStackClasses'

interface StackProps {
  tagName?: string
  gap?: 'sm' | 'md' | 'lg' | 'xl' | 'none'
  display?: 'inline' | 'block'
  direction?: 'row' | 'row-reverse' | 'col' | 'col-reverse'
  justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly'
  alignItems?: 'start' | 'end' | 'center' | 'stretch' | 'baseline'
}

const props = withDefaults(defineProps<StackProps>(), {
  tagName: 'div',
  gap: 'none',
  display: 'block',
  direction: 'col',
  justifyContent: 'start',
  alignItems: 'start',
})

const classes = computed(() => {
  return getStackClasses({
    gap: props.gap,
    display: props.display,
    direction: props.direction,
    justifyContent: props.justifyContent,
    alignItems: props.alignItems,
  })
})
</script>

<script lang="ts">
export default {
  name: 'VtStack',
}
</script>
